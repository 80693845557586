body .p-editor-container .ql-snow.ql-toolbar button:hover, 
body .p-editor-container .ql-snow .ql-toolbar button:hover, 
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover, 
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover,  
body .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke, 
body .p-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke, 
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, 
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, 
body .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke-miter, 
body .p-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke-miter, 
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, 
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, 

body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, 
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, 
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, 
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,

body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active, 
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active, 
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, 
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, 
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, 
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter
{
	color: #FFFA;
	stroke: #FFFa;
}

body .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke, 
body .p-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke, 
body .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, 
body .p-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter
{
	stroke: #FFFa;
	color: #FFFa;
}

body .p-editor-container .ql-snow.ql-toolbar button.ql-active, 
body .p-editor-container .ql-snow .ql-toolbar button.ql-active, 
body .p-editor-container .ql-snow.ql-toolbar button.ql-active, 
body .p-editor-container .ql-snow .ql-toolbar button.ql-active
{
	border: solid 1px #FFF5;
}

body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover, 
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover
{
	color: #42424299;
}

body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected, 
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected
{
	color: #42424299;
}

body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, 
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke 
{
	stroke: #42424299;
}

body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected, 
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected,
body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .p-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
	stroke: pink;
}

body .p-editor-container .ql-snow.ql-toolbar .ql-picker-item svg line {
	stroke: #42424266;
}

body .p-editor-container .ql-snow.p-editor-toolbar {
	border-radius: 12px;
	background-color: #111827;
	border: none;
}

.p-editor-content {
	width: 98%;
	margin-left: 1%;
}

.p-editor-content .ql-editor h1,
.p-editor-content .ql-editor h2,
.p-editor-content .ql-editor p {
	color: #333;
}

.ql-tooltip input {
	color: #424242;
}

.ql-formats button svg {
	stroke: #FFF;
}

.p-editor-content {
	border: 0 !important;
}

.p-editor-content > .ql-editor {
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}