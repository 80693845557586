.reactour__helper {
	color: #424242 !important;
}

.reactour__helper .container {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.reactour__helper .container .content {
	display: grid;
	grid-template-columns: 100px auto;
	align-items: center;
	line-height: 22px;
}

.reactour__helper .container .content > p {
	margin-top: 16px;
}

.reactour__helper .container .content > p.extra {
	font-size: 12px;
	margin-top: 10px;
	gap: 8px;
	display: grid;
	grid-template-columns: 20px auto;
	align-items: center;
	line-height: 14px;
}

.reactour__helper .container .content > p.extra > svg {
	font-size: 18px;
}

.reactour__helper .container .content img {
	grid-row: 1/span 2;
}

@media(max-width: 700px) {
	.reactour__helper .container .content {
		display: flex;
		justify-content: space-between;
	}

	.reactour__helper .container .content img {
		display: none;
	}
}