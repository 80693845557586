.tooltip {
	position: relative;
}
.tooltip:after {
	content: attr(tip);
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	line-height: 1.2em;
	color: #fff;
	width: 60vw;
	max-width: 400px;
	padding: 8px 16px;
	border-radius: 3px;
	background: #222;
	border: solid 1px #222;
	position: absolute;
	top: -6px;
	left: 30px;
	opacity: 0;
	text-align: center;
	transition: 300ms;
	margin-top: -4px;
}
.tooltip.full-width:after {
	max-width: 90vw;
}

.tooltip.fit:after {
	max-width: unset;
	width: 90px;
}

.tooltip.left:after {
	left: -100px;
}

.tooltip:before {
	z-index: 1000;
	position: absolute;
	content: "";
	color: #fff;
	top: 15px;
	left: 0px;
	border-right: 7px transparent solid;
	border-left: 7px transparent solid;
	display: none;
}

.tooltip:hover:after {
	opacity: 0.9;
}
.tooltip:hover:before {
	display: block;
}

.tooltip.center:after {
	top: 60px;
	left: 0;
}

.tooltip.warn:after {
	background-color: #ffeb3b;
	color: #424242;
}

.tooltip.error:after {
	background-color: #f44336;
}

.tooltip.info:after {
	background-color: #2196f3;
}

.tooltip.success:after {
	background-color: #4caf50;
}

.tooltip.white:after {
	background-color: #FFF;
}


.__react_component_tooltip {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: #fff;
}