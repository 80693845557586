/* -----------------------------------------------------
  Pure CSS Progress Bars
  GitHub Project: https://github.com/rkchauhan/pure-css-progress-bars/
  By: Ravikumar Chauhan
  Find me on -
  Twitter: https://twitter.com/rkchauhan01
  Facebook: https://www.facebook.com/ravi032chauhan
  GitHub: https://github.com/rkchauhan
  CodePen: http://codepen.io/rkchauhan
-------------------------------------------------------- */
/* -----------------------------------------------------
  Google Roboto font
-------------------------------------------------------- */
@font-face {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
}
/* -----------------------------------------------------
   CSS Progress Bars
-------------------------------------------------------- */
.cssProgress {
  width: 100%;
  margin-bottom: 20px;
}
.cssProgress .progress1,
.cssProgress .progress2,
.cssProgress .progress3 {
  position: relative;
  overflow: hidden;
  width: 100%;
  font-family: "Roboto", sans-serif;
}
.cssProgress .cssProgress-bar {
  display: block;
  float: left;
  width: 0%;
  height: 100%;
  background: #3798d9;
  box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.1);
  -webkit-transition: width 0.8s ease-in-out;
          transition: width 0.8s ease-in-out;
}
.cssProgress .cssProgress-label {
  position: absolute;
  overflow: hidden;
  left: 0px;
  right: 0px;
  color: #424242;
  font-size: 0.85em;
  text-align: center;
  font-weight: bold;
}
.cssProgress .cssProgress-info {
  background-color: #9575cd !important;
}
.cssProgress .cssProgress-danger {
  background-color: #ef5350 !important;
}
.cssProgress .cssProgress-success {
  background-color: #66bb6a !important;
}
.cssProgress .cssProgress-warning {
  background-color: #ffb74d !important;
}
.cssProgress .cssProgress-right {
  float: right !important;
}
.cssProgress .cssProgress-label-left {
  margin-left: 10px;
  text-align: left !important;
}
.cssProgress .cssProgress-label-right {
  margin-right: 10px;
  text-align: right !important;
}
.cssProgress .cssProgress-label2 {
  display: block;
  margin: 2px 0;
  padding: 0 8px;
  font-size: 0.8em;
}
.cssProgress .cssProgress-label2.cssProgress-label2-right {
  text-align: right;
}
.cssProgress .cssProgress-label2.cssProgress-label2-center {
  text-align: center;
}
.cssProgress .cssProgress-stripes,
.cssProgress .cssProgress-active,
.cssProgress .cssProgress-active-right {
  background-image: -webkit-linear-gradient(135deg, rgba(255, 255, 255, 0.125) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.125) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.125) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.125) 50%, rgba(255, 255, 255, 0.125) 75%, transparent 75%, transparent);
  background-size: 35px 35px;
}
.cssProgress .cssProgress-active {
  -webkit-animation: cssProgressActive 2s linear infinite;
  -ms-animation: cssProgressActive 2s linear infinite;
  animation: cssProgressActive 2s linear infinite;
}
.cssProgress .cssProgress-active-right {
  -webkit-animation: cssProgressActiveRight 2s linear infinite;
  -ms-animation: cssProgressActiveRight 2s linear infinite;
  animation: cssProgressActiveRight 2s linear infinite;
}
@-webkit-keyframes cssProgressActive {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 35px 35px;
  }
}
@-ms-keyframes cssProgressActive {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 35px 35px;
  }
}
@keyframes cssProgressActive {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 35px 35px;
  }
}
@-webkit-keyframes cssProgressActiveRight {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -35px -35px;
  }
}
@-ms-keyframes cssProgressActiveRight {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -35px -35px;
  }
}
@keyframes cssProgressActiveRight {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -35px -35px;
  }
}
/* -----------------------------------------------------
  Progress Bar 1
-------------------------------------------------------- */
.progress1 {
  background-color: #EEE;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.progress1 .cssProgress-bar {
  height: 18px;
}
.progress1 .cssProgress-label {
  line-height: 18px;
}

/* -----------------------------------------------------
   Progress Bar 2
-------------------------------------------------------- */
.progress2 {
  background-color: #EEE;
  border-radius: 9px;
  margin-top: 8px;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.progress2.adjacent {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.progress2 .cssProgress-bar {
  height: 6px;
  border-radius: 9px;
}
.progress2.adjacent .cssProgress-bar {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.progress2 .cssProgress-label {
  line-height: 18px;
}

/* -----------------------------------------------------
   Progress Bar 3
-------------------------------------------------------- */
.progress3 {
  width: auto !important;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.progress3 .cssProgress-bar {
  height: 16px;
  border-radius: 3px;
}
.progress3 .cssProgress-label {
  line-height: 16px;
}

/* -----------------------------------------------------
  Progress Bar 4
-------------------------------------------------------- */
.progress4 {
  position: relative;
  width: 100%;
  background-color: #EEE;
}
.progress4.cssProgress-bg {
  background-color: #bbdefb !important;
}
.progress4.cssProgress-bg-info {
  background-color: #d1c4e9 !important;
}
.progress4.cssProgress-bg-danger {
  background-color: #ffcdd2 !important;
}
.progress4.cssProgress-bg-success {
  background-color: #c8e6c9 !important;
}
.progress4.cssProgress-bg-warning {
  background-color: #ffecb3 !important;
}
.progress4 .cssProgress-bar {
  display: block;
  float: none;
  width: 0%;
  height: 4px;
  background: #3798d9;
}
.progress4 .cssProgress-bar.cssProgress-lg {
  height: 6px;
}
.progress4 .cssProgress-bar.cssProgress-2x {
  height: 8px;
}
.progress4 .cssProgress-bar.cssProgress-3x {
  height: 10px;
}
.progress4 .cssProgress-bar.cssProgress-4x {
  height: 12px;
}
.progress4 .cssProgress-bar.cssProgress-5x {
  height: 14px;
}
.progress4 .cssProgress-bar.cssProgress-glow {
  box-shadow: 5px 0px 15px 0px #3798D9;
}
.progress4 .cssProgress-bar.cssProgress-glow.cssProgress-info {
  box-shadow: 5px 0px 15px 0px #9575cd;
}
.progress4 .cssProgress-bar.cssProgress-glow.cssProgress-danger {
  box-shadow: 5px 0px 15px 0px #ef5350;
}
.progress4 .cssProgress-bar.cssProgress-glow.cssProgress-success {
  box-shadow: 5px 0px 15px 0px #66bb6a;
}
.progress4 .cssProgress-bar.cssProgress-glow.cssProgress-warning {
  box-shadow: 5px 0px 15px 0px #ffb74d;
}
.progress4 .cssProgress-bar.cssProgress-glow-active {
  -webkit-animation: cssProgressGlowActive1 3s linear infinite;
  -ms-animation: cssProgressGlowActive1 3s linear infinite;
  animation: cssProgressGlowActive1 3s linear infinite;
}
.progress4 .cssProgress-bar.cssProgress-glow-active.cssProgress-info {
  -webkit-animation: cssProgressGlowActive2 3s linear infinite;
  -ms-animation: cssProgressGlowActive2 3s linear infinite;
  animation: cssProgressGlowActive2 3s linear infinite;
}
.progress4 .cssProgress-bar.cssProgress-glow-active.cssProgress-danger {
  -webkit-animation: cssProgressGlowActive3 3s linear infinite;
  -ms-animation: cssProgressGlowActive3 3s linear infinite;
  animation: cssProgressGlowActive3 3s linear infinite;
}
.progress4 .cssProgress-bar.cssProgress-glow-active.cssProgress-success {
  -webkit-animation: cssProgressGlowActive4 3s linear infinite;
  -ms-animation: cssProgressGlowActive4 3s linear infinite;
  animation: cssProgressGlowActive4 3s linear infinite;
}
.progress4 .cssProgress-bar.cssProgress-glow-active.cssProgress-warning {
  -webkit-animation: cssProgressGlowActive5 3s linear infinite;
  -ms-animation: cssProgressGlowActive5 3s linear infinite;
  animation: cssProgressGlowActive5 3s linear infinite;
}
@-webkit-keyframes cssProgressGlowActive1 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #3798D9;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #3798D9;
  }
}
@-ms-keyframes cssProgressGlowActive1 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #3798D9;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #3798D9;
  }
}
@keyframes cssProgressGlowActive1 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #3798D9;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #3798D9;
  }
}
@-webkit-keyframes cssProgressGlowActive2 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #9575cd;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #9575cd;
  }
}
@-ms-keyframes cssProgressGlowActive2 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #9575cd;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #9575cd;
  }
}
@keyframes cssProgressGlowActive2 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #9575cd;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #9575cd;
  }
}
@-webkit-keyframes cssProgressGlowActive3 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #ef5350;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #ef5350;
  }
}
@-ms-keyframes cssProgressGlowActive3 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #ef5350;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #ef5350;
  }
}
@keyframes cssProgressGlowActive3 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #ef5350;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #ef5350;
  }
}
@-webkit-keyframes cssProgressGlowActive4 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #66bb6a;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #66bb6a;
  }
}
@-ms-keyframes cssProgressGlowActive4 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #66bb6a;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #66bb6a;
  }
}
@keyframes cssProgressGlowActive4 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #66bb6a;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #66bb6a;
  }
}
@-webkit-keyframes cssProgressGlowActive5 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #ffb74d;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #ffb74d;
  }
}
@-ms-keyframes cssProgressGlowActive5 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #ffb74d;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #ffb74d;
  }
}
@keyframes cssProgressGlowActive5 {
  0%, 100% {
    box-shadow: 5px 0px 15px 0px #ffb74d;
  }
  45% {
    box-shadow: 1px 0px 4px 0px #ffb74d;
  }
}
