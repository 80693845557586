.ReactModal__Overlay {
	opacity: 0;
	transition: opacity 400ms ease-in-out;
	background-color: #0009 !important;
	z-index: 2;
}

.ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModal__Overlay--before-close {
	opacity: 0;
}

.ReactModal__Content {
	width: 80vw;
	background-color: #1e293b !important;
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	border: 0 !important;

	top: 50% !important;
	left: 50% !important;
	right: auto !important;
	bottom: auto !important;
	margin-right: -50% !important;
	transform: translate(-50%, -50%) !important;
	overflow-x: hidden !important;

	@media(max-width: 640px) {
		width: 100vw;
		height: 90vh;
		transform: unset;
	}
}

.ReactModal__Content h1 {
	font-size: 22px;
	font-weight: 500;
	margin: 8px;
}
